// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const DATA_TABLE = gql`
  query ExportDossierListBlockById(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $blockId: ID,
    $context: String,
    $sorters: [DataGridSorterInput],
    $filters: [DataGridFilterInput]
  ) {
    export: exportDossierListBlockById(
      dossierId: $dossierId
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      blockId: $blockId
      context: $context
      sorters: $sorters
      filters: $filters
    ) {
      data
      name
    }
  }
`;

export default DATA_TABLE;
